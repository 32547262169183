<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img
          class="add"
          src="@/assets/address.png"
        >
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link v-if="$route.name === 'SwordCultureDetail'" :to="{name: 'SwordCulture'}">剑文化</router-link>
        <router-link v-else :to="{name: 'Media'}">媒体聚焦</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link v-if="$route.name === 'SwordCultureDetail'" :to="{name: 'SwordCultureDetail', params: {id: this.$route.params.id}}">详情</router-link>
        <router-link v-else :to="{name: 'MediaDetail', params: {id: this.$route.params.id}}">详情</router-link>
      </div>
    </div>
    <div class="detail-wrap">
      <div class="content">
        <h1 class="title">{{info.title}}</h1>
        <p class="time">{{info.createtime | formatdate}}</p>
        <div
          class="text"
          v-html="info.content"
        >
        </div>
      </div>
      <!-- <div class="ln">
        <p>
          <span>上一篇：</span>
          <router-link :to="{name: 'SwordCultureDetail', params: {id: '1'}}">棠溪剑业：漯河客人来“探亲”</router-link>
        </p>
        <p>
          <span>下一篇：</span>
          <router-link :to="{name: 'SwordCultureDetail', params: {id: '1'}}">棠溪剑业：漯河客人来“探亲”</router-link>
        </p>
      </div> -->
      <!-- <p class="more"><span>更多推荐</span></p>
      <ul class="text-list">
        <li>
          <router-link to="/">
            <div class="left">
              <p class="title ellipsis-s">棠溪剑业：漯河客人来“探亲”</p>
              <span class="time">2021-02-23</span>
              <p class="des ellipsis-s">5月31日，漯河市委常委、常务副市长高喜东一行不顾天气炎热前来西平，看望了故乡同门兄长棠溪宝剑传承人高庆民。5月31日，漯河市委常委、常务副市长高喜东一行不顾天气炎热前来西.</p>
              <span class="btn">查看详情+</span>
            </div>
            <div class="right">
              <img src="@/assets/sword_banner.png">
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/">
            <div class="left">
              <p class="title ellipsis-s">棠溪剑业：漯河客人来“探亲”</p>
              <span class="time">2021-02-23</span>
              <p class="des ellipsis-s">5月31日，漯河市委常委、常务副市长高喜东一行不顾天气炎热前来西平，看望了故乡同门兄长棠溪宝剑传承人高庆民。5月31日，漯河市委常委、常务副市长高喜东一行不顾天气炎热前来西.</p>
              <span class="btn">查看详情+</span>
            </div>
            <div class="right">
              <img src="@/assets/sword_banner.png">
            </div>
          </router-link>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from '@/api'
import { formatDate } from '@/utils'
export default {
  name: 'SwordCultureDetail',
  filters: {
    formatdate(time) {
      if (!time) {
        return ''
      }
      return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
    },
  },
  data() {
    return {
      info: {},
    }
  },
  created() {
    getNewsDetail({ id: this.$route.params.id })
      .then((res) => {
        if (res.data.code) {
          this.info = res.data.data
        }
      })
      .catch((err) => {
        console.error(err)
      })
  },
}
</script>

<style lang="less">
@media screen and (min-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > .detail-wrap {
      background: #fff;
      padding: 70px 100px 65px;
      box-sizing: border-box;
      > .content {
        > .title {
          font-size: 28px;
          font-weight: bold;
          color: #333333;
          line-height: 40px;
          text-align: center;
          font-family: PingFangSC-Medium, PingFang SC;
        }
        > .time {
          font-size: 14px;
          color: #999999;
          line-height: 20px;
          text-align: center;
          margin-top: 8px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        > .text {
          font-size: 18px;
          color: #666666;
          line-height: 40px;
          font-family: PingFangSC-Regular, PingFang SC;
          img {
            width: 100% !important;
            margin: 28px auto;
            display: block;
          }
        }
      }
      > .ln {
        margin-top: 32px;
        overflow: hidden;
        font-family: PingFangSC-Regular, PingFang SC;
        > p:nth-child(1) {
          float: left;
          font-size: 18px;
          line-height: 42px;
          > span {
            color: #333333;
          }
          > a {
            color: #d9b078;
          }
        }
        > p:nth-child(2) {
          float: right;
          font-size: 18px;
          line-height: 32px;
          > span {
            color: #333333;
          }
          > a {
            color: #d9b078;
          }
        }
      }
      > .more {
        margin: 44px 0 16px;
        font-size: 18px;
        font-weight: bold;
        line-height: 87px;
        text-align: center;
        background: url('~@/assets/detail_more_bg.png') center;
        background-size: 100% 100%;
        > span {
          padding-right: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > .detail-wrap {
      background: #fff;
      padding: 16px 15px;
      > .content {
        > .title {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 25px;
          text-align: center;
          font-family: PingFangSC-Medium, PingFang SC;
        }
        > .time {
          font-size: 12px;
          color: #999999;
          line-height: 17px;
          text-align: center;
          margin-top: 6px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        > .text {
          font-size: 14px;
          color: #666666;
          line-height: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          img {
            max-width: 100%;
            margin: 16px auto;
            display: block;
          }
        }
      }
      > .ln {
        margin-top: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        > p {
          font-size: 15px;
          line-height: 32px;
          > span {
            color: #333333;
          }
          > a {
            color: #d9b078;
          }
        }
      }
      > .more {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 44px;
        margin: 28px 0 10px;
        text-align: center;
        background: url('~@/assets/detail_more_bg_m.png') center no-repeat;
        background-size: auto 100%;
        > span {
          padding-right: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }
    }
  }
}
</style>